import React from 'react';
import { Route, Switch } from 'react-router-dom';

const HomeComponent = React.lazy(() => import('../pages/home/home'));

const UploadComponent = React.lazy(() => import('../pages/upload/upload'));

const EditComponent = React.lazy(() => import('../pages/edit/edit'));

const DetailComponent = React.lazy(() => import('../pages/detail/detail'));

const DocumentComponent = React.lazy(() => import('../pages/document/document'));

const CommonAppComponent = React.lazy(() => import('../pages/commonApp'));

export const routes = [
  {
    exact: true,
    path: '/',
    dataTestId: 'default',
    component: HomeComponent,
    breadcrumb: 'Resource Center',
  },
  {
    exact: true,
    path: '/upload',
    dataTestId: 'default',
    component: UploadComponent,
    breadcrumb: 'Upload a File',
    title: 'Upload a File',
  },
  {
    exact: true,
    path: '/edit/:id',
    dataTestId: 'default',
    component: EditComponent,
    breadcrumb: 'Edit resource',
    title: 'Edit resource',
  },
  {
    exact: true,
    path: '/detail/:id',
    dataTestId: 'default',
    component: DetailComponent,
    breadcrumb: 'File',
    title: 'File',
  },
  {
    exact: true,
    path: '/document/:id',
    disableNav: true,
    dataTestId: 'default',
    component: DocumentComponent,
  },
  {
    exact: true,
    path: '/common-app',
    disableNav: true,
    detaTestId: 'commonApp',
    component: CommonAppComponent,
  },
];

const AppWrapperRoute: React.FC = () => {
  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={() => <route.component data-test-id={route.dataTestId} />}
        />
      ))}

      <Route>{/* <ErrorPage /> */}</Route>
    </Switch>
  );
};

export default AppWrapperRoute;
