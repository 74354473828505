import { Breadcrumb, Typography } from 'antd';
import styled from 'styled-components';
import { margin, MarginProps } from 'styled-system';

import { Row } from '../Layout/style';

export const BaseNavbar = styled.nav`
  display: flex;
  height: 100px;
  background-color: ${(props) => props.theme.colors.accent};
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 48px;
  padding-right: 48px;
  @media (max-width: ${(props) => props.theme.breakpoints[0]}) {
    display: none;
  }
  .ant-typography {
    color: ${(props) => props.theme.colors.bgWhite};
  }
`;

export const Title = styled(Typography.Text)`
  color: ${(props) => props.theme.colors.bgWhite};
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  margin-top: auto;
`;

export const BaseBreadcrumb = styled(Breadcrumb)`
  &.ant-breadcrumb > span:last-child a {
    color: ${(props) => props.theme.colors.bgWhite};
  }
  &.ant-breadcrumb a {
    color: ${(props) => props.theme.colors.bgWhite};
  }
  .ant-breadcrumb-separator {
    color: ${(props) => props.theme.colors.bgWhite};
    margin-left: 15px;
    margin-right: 20px;
  }

  font-size: 12px;
`;

export const HomeNav = styled(Row)`
  background-color: ${(props) => props.theme.colors.accent};
  .ant-typography {
    color: ${(props) => props.theme.colors.bgWhite};
  }

  @media only screen and (max-width: ${(props) => `${props.theme.breakpoints[0]}`}) {
    display: none;
  }
`;

export const HomeNavHeader = styled(Typography.Text)<MarginProps>`
  width: 202px;
  height: 29px;
  margin-left: 48px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.bgWhite};
  ${margin}
`;
