import { reportError } from '@crimson-education/browser-logger';
import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorView } from './ErrorView';
interface Props {
  children: ReactNode;
}

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {};

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
    console.error('Uncaught error:', error, errorInfo);
    reportError(error, errorInfo);
  }

  public render(): ReactNode {
    if (this.state.error && this.state.errorInfo) {
      return <ErrorView error={this.state.error} errorInfo={this.state.errorInfo} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
