import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html,
body,
#root {
  background-color: ${(props) => props.theme.colors.background};
  font-family: ${(props) => props.theme.fonts.default};
}
`;
