import styled from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  height,
  HeightProps,
  margin,
  MarginProps,
  maxHeight,
  MaxHeightProps,
  maxWidth,
  MaxWidthProps,
  width,
  WidthProps,
} from 'styled-system';

export const Row = styled.div<HeightProps & MarginProps & FlexboxProps & WidthProps & MaxWidthProps & MaxHeightProps>`
  display: flex;
  ${width}
  ${height}
  ${margin}
  ${flexbox}
  ${maxWidth}
  ${maxHeight}
`;

export const Column = styled.div<
  HeightProps & MarginProps & FlexboxProps & WidthProps & MaxWidthProps & MaxHeightProps
>`
  display: flex;
  flex-direction: column;
  ${width}
  ${height}
  ${margin}
  ${flexbox}
  ${maxWidth}
  ${maxHeight}
`;
