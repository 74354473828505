import React from 'react';

import Navbar from '../Navbar';

type LayoutsProps = {
  children: React.ReactNode;
};
const Layout = (props: LayoutsProps): JSX.Element => {
  const { children } = props;
  return (
    <>
      <Navbar />
      {children}
    </>
  );
};

export default Layout;
