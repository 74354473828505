import React from 'react';

type UserInfo = typeof window.xprops.user;
export const UserContext = React.createContext<UserInfo>(null as unknown as UserInfo);

export const withUserContext = <P extends JSX.IntrinsicAttributes>(Comp: React.ComponentType<P>): React.FC<P> => {
  const EnhancedComp = (props: P & JSX.IntrinsicAttributes): JSX.Element => {
    return (
      <UserContext.Provider value={window.xprops.user}>
        <Comp {...props} />
      </UserContext.Provider>
    );
  };
  return EnhancedComp;
};
