import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { routes } from '../../routes/routes';
import { Column, Row } from '../Layout/style';
import { BaseBreadcrumb, BaseNavbar, HomeNav, HomeNavHeader, Title } from './style';

const Navbar = (): JSX.Element | null => {
  const breadcrumbs = useBreadcrumbs(routes, { excludePaths: ['/detail', '/edit'] });
  const location = useLocation();
  const currentMatch = breadcrumbs[breadcrumbs.length - 1].match;
  const { title, disableNav } = routes.find((r) => r.path === (currentMatch as unknown as { path: string }).path) ?? {};
  const frontPageNav = (
    <HomeNav height="100px">
      <Row alignItems="center" marginY="36px">
        <HomeNavHeader>Resource Center</HomeNavHeader>
      </Row>
    </HomeNav>
  );

  return location.pathname === '/' ? (
    frontPageNav
  ) : disableNav ? null : (
    <BaseNavbar>
      <Column>
        <BaseBreadcrumb separator=">">
          {breadcrumbs.map(({ match, breadcrumb }) => (
            <Breadcrumb.Item key={match.url}>
              <Link to={match.url}>{breadcrumb}</Link>
            </Breadcrumb.Item>
          ))}
        </BaseBreadcrumb>
        {title && <Title>{title}</Title>}
      </Column>
    </BaseNavbar>
  );
};

export default Navbar;
