import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { getGraphQLClient } from 'src/graphql';
import * as zoid from 'zoid/dist/zoid.frameworks';
import * as Logger from '@crimson-education/browser-logger';

import { ApolloProvider } from '@apollo/client';

import App, { ApolloClientContext } from './App';
import ErrorBoundary from './components/error/ErrorBoundary';
import { config } from './config';

zoid.create({
  // The html tag used to render my component
  tag: 'resource-component',
  // The url that will be loaded in the iframe or popup, when someone includes my component on their page
  url: config.domain,

  props: {
    token: {
      type: 'string',
      isRequired: false,
    },
    featureSwitches: {
      type: 'object',
      isRequired: false,
    },
    userId: {
      type: 'string',
      isRequired: true,
    },
    authorize: {
      type: 'function',
      isRequired: true,
    },
  },
});

const isLocalhost = window.location.hostname === 'localhost';

Logger.init({
  service: 'resources-frontend',
  environment: config.environment,
  version: config.version,
  defaultMetadata: {
    application: 'resources',
  },
  reporters: {
    log: true,
    datadog: config.datadogApplicationId
      ? {
          applicationId: config.datadogApplicationId,
          clientToken: config.datadogClientToken as string,
          site: config.datadogSite as string,
          proxyUrl: config.datadogTunnelProxyUrl,
          version: config.datadogVersion,
          sampleRate: config.environment === 'production' ? 50 : 0,
          replaySampleRate: config.environment === 'production' ? 50 : 0,
          // Required to use datadog in iframe on https.
          useSecureSessionCookie: !isLocalhost,
          useCrossSiteSessionCookie: !isLocalhost,
          allowedTracingOrigins: [config.resourcesAPIUrl, config.crimsonAppAPIUrl],
          logTransport: config.environment === 'production',
          trackInteractions: true,
          forwardConsoleLogs: false,
        }
      : undefined,
    amplify: config.pinpointAnalyticsAppId
      ? {
          region: config.awsRegion,
          identityPoolId: config.pinpointIdentityPoolId,
          analyticsAppId: config.pinpointAnalyticsAppId,
          proxyUrl: config.pinpointProxyUrl,
          autoTrackPageViews: true,
          autoTrackEvents: true,
          autoTrackSessions: true,
        }
      : undefined,
  },
});

if (config.datadogApplicationId) {
  console.debug('Resources Datadog enabled');
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <React.Suspense fallback={null}>
        <Router>
          <ApolloClientContext.Provider
            value={{
              resourceApiClient: getGraphQLClient(new URL('/graphql', config.resourcesAPIUrl).href, window.xprops),
              crimsonAppApiClient: getGraphQLClient(new URL('/graphql', config.crimsonAppAPIUrl).href, window.xprops),
            }}
          >
            <ApolloProvider client={getGraphQLClient(new URL('/graphql', config.resourcesAPIUrl).href, window.xprops)}>
              <App {...window.xprops} />
            </ApolloProvider>
          </ApolloClientContext.Provider>
        </Router>
      </React.Suspense>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
