import 'antd/dist/antd.less';

import { createContext, FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GlobalStyle } from 'src/theme/globalStyles';
import { ThemeProvider } from 'styled-components';
import { featureSwitches, FeatureToggle, useIdentifyUser, FeatureSwitchEnhancer } from './featureSwitches';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import * as Logger from '@crimson-education/browser-logger';

import Layout from './components/Layout';
import AppWrapperRoute from './routes/routes';
import { defaultTheme } from './theme';
import { withUserContext } from './contexts/user';

type onMessageProps = {
  event: string;
  data: string;
};

export type AppProps = {
  onMessage?: (props: onMessageProps) => void;
  registerEventEmitter?: (eventName: string, callback: (data: string) => void) => void;
  unregisterEventEmitter?: (eventName: string) => void;
};

export const ApolloClientContext = createContext<
  | { resourceApiClient: ApolloClient<NormalizedCacheObject>; crimsonAppApiClient: ApolloClient<NormalizedCacheObject> }
  | Record<string, never>
>({});

const App: FC<AppProps> = (props: AppProps) => {
  const { onMessage, registerEventEmitter, unregisterEventEmitter } = props;
  const location = useLocation();
  const history = useHistory();

  // Make sure the page opened in the iframe
  useEffect(() => {
    if (window != window.top && registerEventEmitter) {
      registerEventEmitter('refresh', (url: string) => {
        history.replace(url.replace('/resource', '') || '/');
      });
    }
    return () => {
      unregisterEventEmitter?.('refresh');
    };
  });

  useEffect(() => {
    const url = location.pathname + location.search;
    onMessage?.({ event: 'route', data: url });
  }, [location.pathname, location.search, onMessage]);

  const { userId, email, firstName, lastName } = window.xprops.user ?? {};
  useEffect(() => {
    Logger.setUser(
      userId
        ? {
            id: userId,
            email,
            name: `${firstName ?? ''} ${lastName ?? ''}`.trim(),
            username: email,
          }
        : null,
    );

    if (featureSwitches.LOGGER_RECORD_SESSION()) {
      if (userId) {
        Logger.recordSession();
      } else {
        Logger.recordSessionStop();
      }
    }
  }, [email, firstName, lastName, userId]);

  const identifyUser = useIdentifyUser();
  useEffect(() => {
    if (!userId) return;
    identifyUser(userId);
  }, [userId, identifyUser]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <FeatureToggle />
      <GlobalStyle />
      <Layout>
        <AppWrapperRoute />
      </Layout>
    </ThemeProvider>
  );
};

export default FeatureSwitchEnhancer(
  withUserContext(App as unknown as React.ComponentType<JSX.IntrinsicAttributes>),
) as FC<AppProps>;
